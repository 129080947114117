import React, { Component } from "react"
import { Link } from "gatsby"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import ListGroup from "react-bootstrap/ListGroup"
import Image from "react-bootstrap/Image"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faPhoneAlt,
  faEnvelope,
  faFileDownload,
} from "@fortawesome/free-solid-svg-icons"
import "bootstrap/dist/css/bootstrap.min.css"

import "./footer.scss"
import {
  faFacebook,
  faInstagram,
  faVk,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons"

export default class Footer extends Component {
  render() {
    return (
      <>
        <footer>
          <Container
            fluid
            className="pt-5 mt-5 border-bottom footer-container"
            id="footer-contacts"
          >
            <Row>
              <Col md={12} sm={12} lg={12} xl={12}>
                {/* <Col
                  xl={3}
                  lg={3}
                  md={7}
                  sm={12}
                  xs={12}
                  id="footer-services"
                  className="p-0 float-left mb-5"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Решения и услуги
                  </h5>
                  <ListGroup className="pr-3">
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Проектирование и адаптация информационных систем
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Диспетчеризация и автоматизация инженерных систем
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Обслуживание и ремонт телекоммуникационного оборудования
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Внедрение / интеграция телекоммуникационных систем
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Поставка оборудования и ПО
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#services" className="bullet-link">
                        Сервисная и гарантийная поддержка 24 / 7 / 365
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </Col> */}
                <Col
                  xl={3}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  className="p-0 float-left mb-5"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Напишите нам
                  </h5>
                  <ListGroup>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a href="mailto:sales@telisit.ru">
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faEnvelope}
                        />{" "}
                        В отдел продаж
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a href="mailto:snab@telisit.ru">
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faEnvelope}
                        />{" "}
                        В отдел снабжения
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a href="mailto:doc@telisit.ru">
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faEnvelope}
                        />{" "}
                        В бухгалтерию
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a href="mailto:support@telisit.ru">
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faEnvelope}
                        />{" "}
                        В поддержку
                      </a>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col
                  xl={3}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  className="p-0 mb-5 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Связаться с нами
                  </h5>
                  <ListGroup>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a
                        href="https://vk.com/telisit/"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon className="mr-1 fa-lg" icon={faVk} />{" "}
                        ВКонтакте
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a
                        href="https://www.instagram.com/teliscompany/"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faInstagram}
                        />{" "}
                        Instagram
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a
                        href="https://www.facebook.com/telisit.ru/"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faFacebook}
                        />{" "}
                        Facebook
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a
                        href="https://www.youtube.com/channel/UCrvK4J60aXmvks_dTyal2BQ"
                        rel="noopener noreferrer nofollow"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faYoutube}
                        />{" "}
                        YouTube
                      </a>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <a href="tel:+7-812-941-4837">
                        <FontAwesomeIcon
                          className="mr-1 fa-lg"
                          icon={faPhoneAlt}
                        />{" "}
                        8 (812) 941-48-37
                      </a>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col
                  id="partners-column"
                  xl={3}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  className="p-0 mb-5 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Сотрудничество
                  </h5>
                  <ListGroup>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/#partners-clients" className="bullet-link">
                        Партнеры и клиенты
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col
                  id="telis-column"
                  xl={3}
                  lg={3}
                  md={12}
                  sm={12}
                  xs={12}
                  className="p-0 mb-5 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Телис
                  </h5>
                  <ListGroup>
                    {/*      <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                        <Link to="#" className="bullet-link">
                        О нас
                      </Link> 
                    </ListGroup.Item> */}
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/blog" className="bullet-link">
                        Блог
                      </Link>
                    </ListGroup.Item>
                    <ListGroup.Item className="bg-transparent border-0 p-0 mb-2">
                      <Link to="/quizzes" className="bullet-link">
                        Тесты по электробезопасности
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Col>
              <Col md={12}>
                <div className="py-4 d-flex justify-content-center align-items-center footer-dl-links">
                  <a
                    target="_blank"
                    className="mr-3"
                    href="../../../documents/politikaconftelisit.pdf"
                  >
                    <u>
                      <FontAwesomeIcon
                        className="mr-1 fa-lg"
                        icon={faFileDownload}
                      />{" "}
                      Политика конфиденциальности
                    </u>
                  </a>
                  <a
                    target="_blank"
                    className="mr-3 company-info-dl"
                    href="../../../documents/telis_company_info.pdf"
                  >
                    <u>
                      <FontAwesomeIcon
                        className="mr-1 fa-lg"
                        icon={faFileDownload}
                      />{" "}
                      Реквизиты
                    </u>
                  </a>
                  <div className="nav-link pl-0">
                    <ThemeToggler>
                      {({ theme, toggleTheme }) => (
                        /*    <label className="container-label m-0">
                      <input
                        type="checkbox"
                        onChange={e =>
                          toggleTheme(e.target.checked ? "dark" : "light")
                        }
                        checked={theme === "dark"}
                      />{" "}
                      Dark mode
                      <span className="checkmark"></span>
                    </label> */
                        <div className="darkThemeToggler">
                          <input
                            type="checkbox"
                            value="None"
                            id="darkThemeToggler"
                            onChange={e =>
                              toggleTheme(e.target.checked ? "dark" : "light")
                            }
                            checked={theme === "dark"}
                          />
                          <label for="darkThemeToggler" className="m-0"></label>
                        </div>
                      )}
                    </ThemeToggler>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <p
                  className="ml-4 mx-auto text-center footer-copyright
                  "
                >
                  &copy; 2016-{new Date().getFullYear()} Телис
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    )
  }
}
