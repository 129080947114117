/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Footer from "./footer"
import CookieConsent from "react-cookie-consent"
import "./layout.scss"

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]')
}

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <main>{children}</main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="OK"
        style={{ background: "#fff", color: "#222", zIndex: 9999 }}
        buttonStyle={{
          color: "#222",
          background: "transparent",
          border: "1px solid #222",
        }}
      >
        Сайт Телис использует файлы cookie, продолжая работу с telisit.ru, вы
        подтверждаете использование сайтом cookies вашего браузера. Вы также
        можете запретить сохранение файлов cookie в настройках браузера.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
